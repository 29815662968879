import "jquery";

$ = jQuery;

function placeholderActive(selector) {
  return !!document.querySelector(selector + ":placeholder-shown");
}

function gformCheckField(getID) {
  let inputContainer = $(".emailFieldCheck").find(".ginput_container");
  let active = placeholderActive(`#${getID}`);

  if (active) inputContainer.css("opacity", "");
  if (!active) inputContainer.css("opacity", 1);
}

let inputId = $(".emailFieldCheck").find("input[type=email]").attr("id");
let inputContainer = $(`input#${inputId}`);

inputContainer.on("keyup", function () {
  gformCheckField(inputId);
});
