const doc = document
const html_style = doc.querySelector('html').style
const body = doc.body
const win = window
const update = () =>
{
  html_style.setProperty('--scrollbar-width', (win.innerWidth - body.scrollWidth) + 'px');
}

update()
doc.addEventListener('DOMContentLoaded', update)
win.addEventListener('resize', update, true)
win.addEventListener('orientationchange', update, true)
