export function getTranslationsForDomain(domain)
{
  const translations = window.app_i18n ?? []
  return translations[domain] ?? []
}

export function getTranslationForDomain(key, domain)
{
  return getTranslationsForDomain(domain)[key]
}

export function __(key, domain)
{
  const translation = getTranslationForDomain(key, domain) ?? [key]
  return translation[0]
}
