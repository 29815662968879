export default function querySelectorOrCreate(container, selector, create = () =>
{
}, append = true)
{
  let el = container.querySelector(selector);
  if (el)
  {
    return el
  }
  el = create(container);
  if (append)
  {
    container.appendChild(el)
  }
  return el
}
