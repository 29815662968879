import {updateEqualHeights} from "./fixEqualHeights";

const doc = document

// splide is lazy loaded. not every page needs it :)
const splide_dep = import('@splidejs/splide')
const splide_css = import('@splidejs/splide/dist/css/splide-core.min.css')
// const splide_css2 = import('@splidejs/splide/dist/css/themes/splide-default.min.css')

const update = async () =>
{
  const splide_containers = Array.from(doc.querySelectorAll('.splide:not(.is-initialized)'))
  for (const container of splide_containers)
  {
    const {Splide} = await splide_dep
    await splide_css
    // await splide_css2
    // console.log({Splide, container})
    const splide = new Splide(container)

    container.addEventListener('click', e =>
    {
      const {target} = e
      const goButton = target.closest(`[aria-controls=${container.id}][data-slide-go]`)
      console.log({goButton})
      if (goButton)
      {
        e.preventDefault()
        let go = goButton.dataset.slideGo
        if (Number(go).toString() === go)
        {
          go = Number(go)
        }
        console.log({go})
        splide.go(go)
      }
    })
    splide.on('mounted updated refresh resized', () => updateEqualHeights())

    splide.mount()
    // console.log(container)
  }
}
update()
// doc.addEventListener('DOMContentLoaded', update)
