const doc = document

const js_dep = import('tw-elements/dist/src/js/bs/src/collapse')
let added = false
const setup = async () =>
{
  if (added)
  {
    return
  }
  const toggles = Array.from(doc.querySelectorAll('[data-bs-toggle="collapse"]'))
  if (toggles.length)
  {
    added = true
    await js_dep
  }
}

setup()
