import querySelectorOrCreate from './querySelectorOrCreate'
const doc = document

const onImgResize = entries => entries.forEach(entry =>
{
  const img = entry.target
  img.setAttribute('sizes', img.offsetWidth + 'px');
})
const imgResizer = new ResizeObserver(onImgResize)

const createSubmenuDropdownDescription = () =>
{
  const el = doc.createElement('div')
  el.className = 'submenu-dropdown-description'
  return el
}
const createSubmenuDropdownButton = () =>
{
  const el = doc.createElement('a')
  el.role = 'button'
  el.className = 'submenu-dropdown-button'
  return el
}

const createSubmenuDropdownImg = () =>
{
  const el = doc.createElement('img')
  el.className = 'submenu-dropdown-img'
  el.style.display = 'none'
  imgResizer.observe(el)
  return el
}


const showSubmenu = a =>
{
  const {description, img} = JSON.parse(a.dataset.fields)
  const content = a.closest('.submenu-dropdown').querySelector('.submenu-dropdown-content')
  if (!content)
  {
    return;
  }

  const $description = querySelectorOrCreate(content, '.submenu-dropdown-description', createSubmenuDropdownDescription)
  const $button = querySelectorOrCreate(content, '.submenu-dropdown-button', createSubmenuDropdownButton)
  const $img = querySelectorOrCreate(content, '.submenu-dropdown-img', createSubmenuDropdownImg)

  $description.innerHTML = description

  $button.innerHTML = a.innerHTML;
  $button.target = a.target;
  $button.href = a.href;

  if (img?.src)
  {
    $img.src = img.src
    $img.width = img.width
    $img.height = img.height
    $img.srcset = img.srcset
    $img.alt = img.alt
    $img.style.display = 'block'
  } else
  {
    $img.style.display = 'none'
  }

  // const $link = content.querySelector('.submenu-dropdown-btn')
  // const $description = content.querySelector('.submenu-dropdown-desc')
}
const onSubmenuLinkActive = e => showSubmenu(e.target)
const onSubmenuOpened = e =>
{
  const li = e.target.closest('li')
  const first_submenu_link = li.querySelector('.submenu-dropdown li:first-child > a[data-fields]')
  if (!first_submenu_link)
  {
    return;
  }
  showSubmenu(first_submenu_link)
}

doc.querySelectorAll('.nav-primary ul.nav > li.menu-item-has-children > a').forEach(a =>
{
  a.addEventListener('mouseenter', onSubmenuOpened)
  a.addEventListener('focus', onSubmenuOpened)
})
doc.querySelectorAll('.nav-primary a[data-fields]').forEach(a =>
{
  a.addEventListener('mouseenter', onSubmenuLinkActive)
  a.addEventListener('focus', onSubmenuLinkActive)
})
