import {__} from './lang'

const doc = document
const btn_selector = '[aria-controls=nav-primary-mobile]'
const is_expanded_class = 'nav-primary-mobile--is-expanded'
const not_expanded_class = 'nav-primary-mobile--not-expanded'
const html_class = doc.querySelector('html').classList

const isMobileMenuOpened = () => html_class.contains(is_expanded_class)
const setMobileMenuOpened = (set_expanded = false) =>
{
  html_class.toggle(is_expanded_class, set_expanded)
  html_class.toggle(not_expanded_class, !set_expanded)
  doc.querySelectorAll(btn_selector).forEach(btn =>
    btn.setAttribute('aria-expanded', set_expanded ? 'true' : false))
}
const toggleMobileMenuOpened = () => setMobileMenuOpened(!isMobileMenuOpened())

/*******************************************************************************
 * drilldown functions
 */
let navPrimaryMobile
let drillUpButton
const refreshDrillUpButtonClass = () =>
{
  const has_drillup = navPrimaryMobile.querySelectorAll('ul.drillup').length > 0
  drillUpButton.classList.toggle('visible', has_drillup)

  navPrimaryMobile.classList.toggle('has-drillup', has_drillup)
  navPrimaryMobile.scrollTop = 0
}

const drillDownLink = link =>
{
  link.closest('li').querySelector('ul').classList.add('drilldown')
  link.closest('ul').classList.add('drillup')
  refreshDrillUpButtonClass()
}
const onDrillUp = () =>
{
  const drillup = Array.from(navPrimaryMobile.querySelectorAll('ul.drillup')).pop()
  const drilldown = Array.from(navPrimaryMobile.querySelectorAll('ul.drilldown')).pop()
  // console.log({drillup, drilldown})
  if (drillup)
  {
    drillup.classList.remove('drillup')
  }

  if (drilldown)
  {
    drilldown.classList.remove('drilldown')
  }
  refreshDrillUpButtonClass()
}
const makeDrillUpButton = () =>
{
  const btn = doc.createElement('button')
  btn.classList.add('nav-primary-mobile-drillup')
  btn.textContent = __('Back', 'succeedit')

  drillUpButton = btn
  navPrimaryMobile = doc.querySelector('.nav-primary-mobile')
  navPrimaryMobile.appendChild(btn)
  refreshDrillUpButtonClass()
}
/*******************************************************************************
 * events
 */
doc.addEventListener('click', e =>
{
  const {target} = e
  if (target.closest(btn_selector))
  {
    e.preventDefault()
    toggleMobileMenuOpened()
    return
  }
  if (target.closest('#nav-primary-mobile .primary-wrap .menu-item-has-children > a[href]'))
  {
    e.preventDefault()
    drillDownLink(target)
    return
  }
})
setMobileMenuOpened(false)
makeDrillUpButton()
drillUpButton.addEventListener('click', onDrillUp)
