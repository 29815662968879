import equalHeights from "./equalHeights";

export function updateEqualHeights()
{
  equalHeights('.columns_block_img', true)
  equalHeights('.columns_block_title', true)
  //equalHeights('.posts .entry-image', true)
  equalHeights('.posts .entry-categories', true)
  equalHeights('.posts .entry-title', true)
  equalHeights('.posts .entry-summary', true)
}

let last_width = 0
let last_height = 0
function enqueueEqualHeights()
{
  requestAnimationFrame(() =>
  {
    const width = window.innerWidth
    const height = window.innerHeight
    if (width !== last_width || height !== last_height)
    {
      updateEqualHeights()
    }
    last_width = width
    last_height = height
    enqueueEqualHeights()
  })
}

enqueueEqualHeights()
