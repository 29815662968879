const doc = document
export default function equalHeights(selector)
{
  const elements = Array.from(doc.querySelectorAll(selector))
  let groups = {}

  elements.forEach(element =>
  {
    element.style.height = 'auto'
    const top = element.offsetTop
    const height = element.offsetHeight
    if (top in groups)
    {
      groups[top].elements.push(element)
      if (height > groups[top].height)
      {
        groups[top].height = height
      }
    } else
    {
      groups[top] = {elements: [element], height}
    }
  })
  console.log({selector, groups})

  Object.values(groups).forEach(
    group => group.elements.forEach(
      element => element.style.height = group.height + 'px'))

}
